document.addEventListener('DOMContentLoaded', () => {
  // text animation
  (() => {
    const animationText = document.querySelectorAll('.animation-breathe');
    if (!animationText) return;

    animationText.forEach((element) => {
      if (element.textContent) {
        const letters = element.textContent.split('');
        element.textContent = '';
        letters?.forEach((letter, index) => {
          const span = document.createElement('span');
          span.textContent = letter;
          span.style.animationDelay = `${index * 50}ms`;
          element.append(span);
        });
      }
    });
  })();

  // JezMcK SVG animation
  (() => {
    setInterval(() => {
      const svg = document.getElementsByClassName('animated')[0];
      if (svg) {
        svg.classList.toggle('animate');
      } else {
        console.warn('not found');
      }
    }, 2000);
  })();
});
